/* xl, large: 1200px */
$breakpoint-xl: 1275px;
/* lg, large: 992px */
$breakpoint-lg: 1020px;
/* md, large: 768px */
$breakpoint-md: 768px;
/* sm, large: 575px */
$breakpoint-sm: 575px;

@media screen and (max-width: $breakpoint-xl) {
  section {
    padding: 0 40px;
  }
}

@media screen and (max-width: $breakpoint-lg) {
  section {
    max-width: 600px;
    padding: 0 20px;
  }

  .banner {
    section {
      padding-top: 0 !important;

      .banner__inner {
        .banner_left {
          width: 100%;
          max-width: 380px;
          margin: auto;
          padding-top: 24px;

          h1 {
            font-size: 38px;
            line-height: 1.3;
          }

          .stars {
            margin: 0 auto 24px;
          }

          span {
            text-align: center;
          }
        }
      }
    }
  }

  .feat {
    .feat__inner {
      .feat__item {
        width: calc(50% - 4px);
      }
    }
  }

  .partner {
    section {
      .partner__inner {
        flex-direction: column;

        .partner__right {
          width: 100%;

          h2 {
            font-size: 24px;
            text-align: center;
          }

          p {
            font-size: 14px;
            text-align: center;
          }

          .ant-btn {
            margin: auto;
          }
        }

        .partner__left {
          width: 100%;
          max-width: 344px;
        }
      }
    }
  }

  footer {
    section:last-of-type {
      max-width: 100%;

      .links {
        flex-direction: column;

        .links__info {
          .online-text {
            display: block;
            margin-bottom: 10px;
          }
        }

        ul {
          margin-bottom: 20px;

          &:first-of-type {
            padding-bottom: 20px;
            border-bottom: 1px solid #e5e5e5;
          }

          &:last-of-type {
            margin-bottom: 40px;
          }
        }
      }
    }
  }

  .currencies {
    section {
      .currency__header {
        h1 {
          font-size: 38px;
          line-height: 1.3;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .currencies {
    section {
      .currency__header {
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;

        .currency__header__input {
          width: 100%;

          input {
            width: 100%;
          }
        }
      }

      .currency__list .currency__item {
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .ant-layout-header {
    .head {
      .logo {
        img {
          max-width: 260px;
        }
      }

      .search,
      .menu {
        display: none;
      }
    }
  }

  .banner {
    section {
      padding-top: 0 !important;

      .banner__inner {
        .banner_left {
          h1 {
            font-size: 30px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: #eaecef;
          }

          .stars {
            margin: 0 auto 24px;
          }

          span {
            text-align: center;
          }
        }
      }
    }
  }

  .currencies section {
    .currency__header {
      h1 {
        font-size: 30px;
        text-align: left;
      }
    }

    .currency__list .currency__item .currency__item__action .ant-btn {
      width: 100%;
    }
  }
}
