// Core
.ant-col {
  font-family: "Open Sans", sans-serif;
  min-height: auto;
}

// Input
.ant-input {
  width: 100%;
  color: #cfd9db;
  font-size: 16px;
  font-weight: 400;
  height: 46px;
  border-radius: 3px;
  background: #2d3537;
  border: 1px solid #2d3537;
  outline: none;
  box-shadow: none;

  &::placeholder {
    color: #6c757d;
  }

  &:hover {
    box-shadow: none;
    background: #283031;
    border-color: #323a3d;
  }

  &:focus {
    border: 1px solid rgba(154, 163, 165, 0.35) !important;
    box-shadow: none !important;
  }
}

.ant-input-number {
  width: 100%;
  color: #cfd9db;
  font-size: 16px;
  font-weight: 400;
  height: 46px;
  border-radius: 3px;
  background: #2d3537;
  border: 1px solid #2d3537;
  outline: none;
  box-shadow: none;

  .ant-input-number-input {
    color: #cfd9db;
    font-size: 16px;
    font-weight: 400;
    height: 46px;

    &::placeholder {
      color: #6c757d;
    }
  }

  &::placeholder {
    color: #6c757d;
  }

  &:hover {
    box-shadow: none;
    background: #283031;
    border-color: #323a3d;
  }
}

// Selcet
.ant-select:not(.ant-select-customize-input) {
  box-shadow: none !important;

  .ant-select-selector {
    cursor: pointer;
    color: #cfd9db;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    background: #52585a;
    line-height: 34px;
    border: 1px solid rgba(154, 163, 165, 0.35);
    border-radius: 3px;
    outline: none;
    box-shadow: none;

    .ant-select-selection-item {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search-input {
      position: relative;
      cursor: pointer !important;
      left: -10px;
      width: calc(100% - 20px);
      height: 32px !important;
      color: #818d8f !important;
      padding: 3px 10px;
      margin: 6px 0;
      border: none !important;
      outline: none !important;
      border-radius: 4px;
      background-color: #2d3537;
      opacity: 0;
      z-index: 1;
    }
  }

  &.ant-select-focused {
    box-shadow: none !important;

    .ant-select-selector {
      border: 1px solid rgba(154, 163, 165, 0.35) !important;

      .ant-select-selection-search-input {
        opacity: 1 !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
    box-shadow: none !important;

    .ant-select-selector {
      background: #4d5355;
      border-color: rgba(173, 180, 182, 0.35) !important;
    }
  }

  .ant-select-arrow {
    inset-inline-end: 1px;
    margin-top: -4px;
  }
}

.ant-select-single {
  height: 46px;
  min-width: 212.5px;

  &.ant-select-open {
    .ant-select-selection-item {
      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
  &.ant-select-show-arrow {
    .ant-select-selection-item {
      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }
}

// Select Dropdown
.ant-select-dropdown {
  color: #cfd9db;
  font-size: 16px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  background: #52585a;
  line-height: 34px;
  padding: 0;
  border: 1px solid rgba(154, 163, 165, 0.35);
  border-radius: 0px 0px 3px 3px;
  outline: none;
  box-shadow: none;
  margin-top: -5px;

  .ant-select-item {
    min-height: 46px;
    border-radius: 0;
    margin-right: 12px;

    .ant-select-item-option-content {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      font-family: "Open Sans", sans-serif;

      img {
        margin-right: 7px;
        height: 20px;
        width: 20px;
      }
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #fff !important;
    font-weight: 600;
    background-color: #2d3537;
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #323a3c;
  }

  &::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 100%;
    top: 0;
    right: 0;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #fff0;
  }
}

// Checkbox
.ant-checkbox-wrapper {
  width: 100%;

  &:hover {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #2d3537 !important;
        border-color: transparent !important;
      }
    }
  }
}

.ant-checkbox {
  top: 3px;
  align-self: flex-start;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    border: none;
    overflow: hidden;
    border-radius: 3px;
    background-color: #2d3537;
    box-shadow: inset 0 0 0 1px #2d3537;

    &:after {
      top: -7px;
      width: 6px;
      height: 10px;
      inset-inline-start: 29.5%;
      border: 2px solid #61c788;
      border-top: 0;
      border-inline-start: 0;
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
    }
  }

  + span {
    color: #cfd9db;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    &:after {
      top: 45%;
    }
  }
}

// Modal
.ant-modal {
  .ant-modal-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    border: 0;
    text-align: left;
    font-family: "Open Sans", sans-serif;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-qrcode {
    border: none;
    margin: 40px auto 0;
  }

  &.modal-delete,
  &.modal-pay {
    .ant-modal-title {
      text-align: center;
    }

    .d-flex {
      gap: 5px;
      margin-top: 30px;
      justify-content: center;
    }
  }

  &.modal-pay {
    p {
      color: #000;
      font-size: 17px;
      margin-top: 30px;
    }
  }
}

// DropDown Lang
.ant-dropdown-placement-bottomLeft {
  .ant-dropdown-menu {
    color: #cfd9db;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    background: #587282;
    line-height: 34px;
    padding: 0;
    border: 1px solid rgba(154, 163, 165, 0.35);
    border-radius: 0px 0px 3px 3px;
    outline: none;
    box-shadow: none;
    margin-top: -5px;

    .ant-dropdown-menu-item {
      padding: 5px 11px;
      text-align: left;
      border-radius: 0;

      &:hover {
        background-color: #2f3d45;
      }
    }
  }
}
