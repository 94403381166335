/* -----------------------------------------------

Table of Content

	1. Body and Core Css
     - 1.1 Typography
     - 1.2 Button Style
     - 1.3 Form Style

  2. Home Page
     - 2.1 Banner Section

  3. Generator Page

----------------------------------- */

/* -----------------------------------
		    1. Body and Core Css
----------------------------------- */

* {
  margin: 0;
  padding: 0;
  font-family: inherit;
  outline: none !important;
  list-style: none !important;
  text-decoration: none !important;

  &:after,
  &:before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  font: {
    size: 16px;
    weight: 400;
    family: "Onest", sans-serif;
  }
  text-align: center;
  color: #fff;
  background: #181a1f;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  max-width: 1295px;
  margin: auto;
  padding: 0 90px;
}

a {
  color: #ffffff;
  outline: none;
  text-decoration: none;

  &:hover {
    outline: none;
    text-decoration: none;
    border-bottom: 1px solid #ffffff;
  }
}

p {
  font-size: 16px;
  color: #fff;
  margin-top: 0;
  margin-bottom: 1rem;
}

img {
  max-width: 100%;
}

dl,
ol,
ul {
  padding: 0;
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 0px 30px;
  min-height: 49px;
  border-color: #61c788;
  background-color: #61c788;
  box-shadow: 0px 2px 0px #338f56;
  transform: translateY(0);
  transition: all 0.3s ease;

  &:hover {
    border-color: #40b56d;
    background-color: #40b56d !important;
    transform: translateY(2px);
    box-shadow: 0px 0px 0px #256a3f;
  }

  &:active {
    &:not(:disabled) {
      &:not(.disabled) {
        border-color: #40b56d;
        background-color: #40b56d !important;
        transform: translateY(2px);
        box-shadow: 0px 0px 0px #256a3f;
        box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  &:focus {
    border-color: #61c788;
    background-color: #61c788;
    box-shadow: 0 0 0 3px rgba(97, 199, 136, 0.3);
  }

  &.ant-btn-disabled {
    color: #fff;
    border-color: #61c788;
    background-color: #61c788;
    opacity: 0.25;
    cursor: default;
  }
}

.btn-second {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #61c788;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: auto;
  border-radius: 5px;
  border: 2px solid #61c788;
  background-color: transparent;
  padding: 0 30px;
  min-height: 38px;
  text-transform: uppercase;
  transition: all 0.3s;

  svg {
    margin-right: 0.5rem !important;
  }

  &:hover {
    color: #fff !important;
    background-color: #61c788 !important;
    border-color: #61c788;

    svg {
      path {
        fill: #fff;
      }
    }
  }

  &.grey {
    color: #a9b3bf !important;
    background-color: transparent !important;
    border-color: #a9b3bf;

    &:hover {
      color: #fff !important;
      background-color: #a9b3bf !important;
      border-color: #a9b3bf !important;

      svg {
        line,
        polyline {
          stroke: #fff;
        }
      }
    }
  }

  &.red {
    color: #df7d7d;
    background-color: transparent;
    border-color: #df7d7d;

    &:hover {
      color: #fff !important;
      background-color: #df7d7d !important;
      border-color: #df7d7d;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
}

// Header
.top__info {
  background: #eecb12;
  position: relative;
  padding: 8px 20px;

  h4 {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: #000;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    padding-bottom: 8px;
    text-align: left;
    color: #000;
    margin-bottom: 0;
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    height: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../../img/close.svg);
  }

  &.closed {
    display: none;
  }
}

.ant-layout-header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 40px;
  height: 74px;
  top: 0;
  padding: 0;
  background: transparent;
  border-bottom: 1px solid #e5e5e5;
  z-index: 9;

  section {
    width: 100%;
    max-width: 1320px;
    padding: 0 20px;
    display: flex;
    justify-content: flex-start;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      border: none !important;
      margin-right: 20px;

      img {
        max-width: 320px;
      }
    }

    .search {
      display: flex;
      background-color: transparent;
      border: none;
      width: 25px;
      height: 25px;
      position: relative;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;
        -o-object-fit: contain;
        object-fit: contain;
      }
    }

    .menu {
      display: flex;
      font-size: 14px;
      position: relative;
      transform: none;
      top: 0;
      padding: 0;
      flex-direction: row;
      align-items: center;
      overflow: visible;
      margin-left: 20px;

      a {
        position: relative;
        color: #ffffff;
        height: 55px !important;
        display: flex;
        align-items: center;
        font-size: 14px;
        border: none !important;

        &::after {
          content: "";
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          margin: auto;
          width: 100%;
          color: transparent;
          background: #eecb12;
          height: 3px;
          transition: all 0.5s;
        }
      }
    }
  }
}

.banner {
  position: relative;
  margin-bottom: 150px;

  section {
    padding-top: 68px !important;

    .banner__inner {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      gap: 104px;

      .banner_left {
        display: flex;
        flex-direction: column;
        width: 50%;
        order: 2;
        margin-top: 55px;

        h1 {
          font-size: 48px;
          text-align: left;
          margin-left: auto;
          margin-right: auto;
          color: #eaecef;
          font-weight: 700;
          line-height: 1.3;
          margin-bottom: 0;
          padding-bottom: 36px !important;
        }

        p {
          position: relative;
          margin-bottom: 20px;
          line-height: 1.2;
          color: #eaecef;
          text-align: left;
          font-size: 14px;

          img {
            width: 16px;
            height: 16px;
            margin-right: 20px;
          }
        }

        .stars {
          width: 240px;
          height: 44px;
          margin-bottom: 24px;
        }

        span {
          color: #ffffff;
          font-size: 18px;
          line-height: 22px;
          text-align: left;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

.feat {
  margin-bottom: 100px;

  .feat__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .feat__item {
      width: calc(20% - 8px);
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      padding: 40px 28px;
      background-color: #1f2328;
      border-radius: 4px;
      margin-bottom: 8px;
      transition: all 0.25s ease 0s;

      img {
        margin-bottom: 20px;
      }

      h4 {
        font-size: 18px;
        line-height: 1.2;
        color: #eaecef;
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}

.partner {
  padding-bottom: 80px;

  section {
    .partner__inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 40px;

      .partner__right {
        width: 50%;

        h2 {
          text-align: left;
          font-size: 36px;
          font-weight: 700;
          color: #ffffff;
        }

        p {
          text-align: left;
          font-size: 18px;
          max-width: 570px;
          margin-bottom: 40px;
        }

        .ant-btn {
          display: flex;
          height: 55px;
          min-width: 402px;
          font-size: 24px;
          font-family: "Onest", sans-serif;
          padding: 15.5px 24px;
          color: #000000;
          background-color: #eecb12;
          border: none;
          border-radius: 4px;
          align-items: center;
          justify-content: center;
        }
      }

      .partner__left {
        width: 50%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

footer {
  padding-bottom: 100px;
  background-color: #1f2328;

  .item__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    padding: 36px 0;
    margin-bottom: 40px;

    .item {
      img {
        max-width: 200px;
      }
    }
  }

  section {
    &:last-of-type {
      padding: 0 20px;
    }
  }

  .links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;

    ul {
      li {
        display: block;
        position: relative;
        color: #ffffff;
        font-weight: 500;
        font-size: 14px;
        text-align: left;

        &:not(:last-child) {
          margin-bottom: 18px;
        }
      }
    }

    .links__info {
      .online {
        display: flex;
        align-items: center;
        height: 17px;
        font-weight: 400;
        font-size: 14px;
        padding-left: 26px;
        position: relative;
        color: #eecb12;
        margin-bottom: 10px;

        img {
          width: 12px;
          height: 12px;
          cursor: pointer;
          margin-left: 3px;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 17px;
          height: 17px;
          box-sizing: border-box;
          border-radius: 50%;
          background-color: #eecb12;
          border: 3px solid rgba(238, 203, 18, 0.3);
        }
      }

      .online-text {
        font-size: 10px;
        line-height: 1.24;
        max-width: 266px;
        color: #ffffff;
        display: none;
        text-align: left;
      }

      .lang {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        max-width: 90px !important;
        color: #eecb12 !important;
        border: 1px solid #eecb12 !important;
        padding: 6px 43px 7px 24px;
        border-radius: 4px;
        text-transform: uppercase;
        font-size: 16px;

        img {
          position: relative;
          top: 2px;
        }
      }

      a {
        display: flex;
        align-items: flex-end;
        gap: 4px;
        color: #eecb12 !important;
        font-size: 14px;
        line-height: 1.2;
        margin: 9px 0;
        border-bottom: 1px solid transparent;

        svg {
          width: 20px;
          margin-right: 5px;
        }

        &:hover {
          border-color: #eecb12;
        }
      }
    }
  }
}

/* privacy */
.privacy {
  .privacy__content {
    max-width: 900px;
    padding: 40px 20px;

    .date {
      display: block;
      font-size: 14px;
      color: gray;
      text-align: right;
      margin-bottom: 20px;
      line-height: 1.4;
    }

    a {
      color: #eecb12;
    }

    h2 {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 20px;
    }

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
      text-align: center;
      color: #eaecef;
    }

    p {
      font-size: 16px;
      line-height: 1.4;
      text-align: justify;
      margin-bottom: 20px;
      color: #eaecef;

      a {
        color: #eecb12;
      }
    }

    ul {
      list-style: disc;
      padding-left: 20px;

      li {
        font-size: 16px;
        line-height: 1.4;
        text-align: justify;
        margin-bottom: 20px;
        color: #eaecef;
        list-style: disc !important;

        ul {
          margin-top: 20px;
          list-style: circle !important;

          li {
            list-style: circle !important;
          }
        }
      }
    }
  }
}

/* currencies */
.currencies {
  section {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1295px;
    padding: 40px 20px;
    margin: 0 auto;

    .currency__header {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 48px;
        color: #eaecef;
        font-weight: 400;
        margin-bottom: 0;
      }

      .currency__header__input {
        position: relative;

        input {
          display: block;
          width: 316px;
          border: 1px solid #e5e5e5;
          padding: 21px 20px 17px 48px;
          color: #eaecef;
          line-height: 1.2;
          border-radius: 4px;
          background: #000000;

          &::placeholder {
            font-size: 17px;
            line-height: 11px;
            color: #e5e5e5;
            font-family: "Onest";
            line-height: 1.2;
          }
        }

        &::after {
          content: "";
          position: absolute;
          cursor: pointer;
          display: block;
          top: 0;
          left: 5px;
          width: 38px;
          height: 100%;
          background-repeat: no-repeat;
          background-size: 50%;
          background-position: center;
          background-image: url(../../img/search.svg);
        }
      }
    }

    .currency__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .currency__item {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: calc(50% - 19px);
        margin-bottom: 40px;
        padding: 20px;
        background-color: #000000;
        border-radius: 4px;
        box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.05);

        .currency__item__icon {
          flex: none;
          width: 35px;
          height: 35px;
          overflow: hidden;
          border-radius: 50%;
          margin-right: 20px;
        }

        .currency__item__info {
          display: flex;
          flex-direction: column;
          padding-top: 7.5px;
          margin-right: 20px;

          .currency__info__name {
            font-weight: 500;
            font-size: 24px;
            line-height: 0.8;
            color: #ffffff;
            margin-bottom: 20px;
          }

          .currency__info__symbol {
            color: #9f9f9f;
            line-height: 0.8;
            font-size: 18px;
            text-align: left;
          }
        }

        .currency__item__action {
          width: 100%;
          display: flex;
          margin-left: auto;
          gap: 20px;
          margin-top: 20px;

          .ant-btn {
            display: flex;
            height: 46px;
            min-width: 124px;
            font-size: 14px;
            border: 1px solid #eecb12;
            text-align: center;
            padding: 16px 10px 14px;
            font-family: "Onest";
            color: #eecb12 !important;
            background-color: transparent;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
